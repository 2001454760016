import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
// import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

import useLocale from "../../hooks/use-locale"

import facebookImage from "../../images/meta/share-fb.jpg"
import twitterImage from "../../images/meta/share-tw.jpg"

const langKeyMap = {
  ca: "en-CA",
  en: "en-US",
  fr: "fr-CA",
  es: "es-419",
}
function SEO({ description, meta, title }) {
  // const { pathname } = useLocation()
  const { site } = useStaticQuery(query)

  const { langKey, globalLocale } = useLocale()
  const metaDescription = description || globalLocale.meta.description
  const metaTitle = title
    ? `${globalLocale.meta.title} – ${title}`
    : globalLocale.meta.title

  const { siteUrl } = site.siteMetadata

  return (
    <Helmet
      htmlAttributes={{
        lang: langKeyMap[langKey],
      }}
      title={metaTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:url`,
          content: siteUrl + (langKey !== "en" ? `/${langKey}/` : ""),
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: "og:image",
          content: `${siteUrl}${facebookImage}`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: globalLocale.meta.author,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          property: "twitter:image",
          content: `${siteUrl}${twitterImage}`,
        },
        {
          name: "google-site-verification",
          content: "lcC2m3GxLFXRpw4s4cKbn8Q6jDs8zHPXp9CC1xHn70c",
        },
        {
          name: "google-site-verification",
          content: "2oRwt7x7G0A4Au8TewNveD9lc_27-ue36DrRXwTgYW4",
        },
        {
          name: "msvalidate.01",
          content: "CD9A42A71E67EDC970DE6AD9919B5D79",
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  meta: [],
  description: ``,
  title: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        siteUrl: siteUrl
      }
    }
  }
`
