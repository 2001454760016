import { useContext, useState, useCallback, useEffect } from "react"

import NcloodContext from "../contexts/nclood-context"
import useLocale from "./use-locale"

export default function useTracking() {
  const { loaded } = useContext(NcloodContext)
  const { langKey } = useLocale()
  const [queue, setQueue] = useState([])

  // tracking
  const country = langKey === "en" ? "us" : ""
  const locale = langKey
  const suite =
    process.env.NODE_ENV === "development" ? "ncomdev,ncomdev" : "ncomglobal"
  const siteType = "portal"
  const channel = `${country}:${siteType}`
  const productCode = null

  const prop1 = channel
  const prop2 = siteType
  const prop6 = `${country}:${locale}`
  const prop10 = "Mario Portal"
  const prop12 = "nintendo"
  const prop14 = siteType
  const prop16 = prop10

  const trackerProps = {
    suite,
    productName: prop1,
    contentType: prop2,
    locale: prop6,
    gameTitle: prop10,
    publisher: prop12,
    siteType: prop14,
    siteName: prop16,
    root: "/",
  }

  const pageTrackOpts = {
    productName: prop1,
    contentType: prop2,
    locale: prop6,
    gameTitle: prop10,
    publisher: prop12,
  }

  // track clicks
  const track = useCallback(
    (type, settings, event) => {
      if (event && event.persist) event.persist()
      const nativeEvent = event && event.nativeEvent ? event.nativeEvent : event

      if (!loaded) {
        setQueue(cur => cur.concat([{ type, settings, event: nativeEvent }]))
      } else if (type === "trackPage") {
        const isHome = settings.toLowerCase() === "home" ? true : false
        const pageName = settings
        // eslint-disable-next-line no-useless-escape
        let vanity = /(\?|\&)r=([a-zA-Z0-9\.]+)/.exec(window.location.href)
        if (vanity && vanity.length === 3) vanity = vanity[2]
        else vanity = null

        try {
          window.nclood.Metrics.use({ ...trackerProps, pageName })
          window.nclood.Metrics.trackPage({ ...pageTrackOpts, isHome, vanity })
        } catch (error) {
          console.error(error)
        }
      } else {
        try {
          window.nclood.Metrics[type](settings, nativeEvent)
        } catch (err) {
          console.error(err)
        }
      }
    },
    [loaded, pageTrackOpts, trackerProps]
  )

  const trackLink = (settings, event) => {
    track("trackLink", settings, event)
  }

  const trackExitLink = (settings, event) => {
    track("trackExitLink", settings, event)
  }

  const trackPage = pageName => {
    if (!pageName) return
    track("trackPage", pageName)
  }

  // this is a queue system to capture clicks and trigger metrics when nclood loads
  useEffect(() => {
    if (!loaded || queue.length <= 0) return

    let didCancel = false
    for (let item of queue) {
      if (!didCancel) track(item.type, item.settings, item.event)
    }

    setQueue([])
    return () => (didCancel = true)
  }, [loaded, queue, track])

  return {
    trackLink,
    trackExitLink,
    trackPage,
  }
}
