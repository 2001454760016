import { useState, useRef, useEffect, useCallback } from "react"
import useEventListener from "./use-event-listener"

const BREAKPOINT = new Map([
  ["S", 1],
  ["M", 2],
  ["L", 3],
  ["XL", 4],
  ["XXL", 5],
])

const useBreakpoints = () => {
  const debounceTimeout = useRef(null)
  const [currentBP, setCurrentBP] = useState(null)

  const updateBreakpoints = useCallback(() => {
    setCurrentBP(
      BREAKPOINT.get(
        window
          .getComputedStyle(document.querySelector("body"), "::after")
          .content.replace(/'|"/gi, "")
      )
    )
  }, [])

  const onResize = useCallback(() => {
    clearTimeout(debounceTimeout.current)
    debounceTimeout.current = setTimeout(() => {
      updateBreakpoints()
    }, 200)
  }, [updateBreakpoints])

  useEventListener("resize", onResize)

  useEffect(() => {
    updateBreakpoints()
    onResize()
  }, [onResize, updateBreakpoints])

  return currentBP
}

export default useBreakpoints
