import React from "react"
import PropTypes from "prop-types"

import useReduceMotion from "../../hooks/use-reduce-motion"
import useLocale from "../../hooks/use-locale"
import useTracking from "../../hooks/use-tracking"

import { classnames } from "../../utils/helpers"

import styles from "./reduce-motion-button.module.scss"
import useIsMounted from "../../hooks/use-is-mounted"

const ReduceMotionButton = () => {
  const [state, on, off] = useReduceMotion()
  const isMounted = useIsMounted()
  const { globalLocale } = useLocale()
  const { trackLink } = useTracking()

  const handleClick = event => {
    if (state === 1) off()
    else on()

    trackLink(
      {
        eVars: {
          60: "reduce motion",
        },
        events: [77],
      },
      event
    )
  }

  if (!isMounted) return null

  return (
    <button
      aria-pressed={state === 1 ? "true" : "false"}
      className={classnames([
        styles.ReduceMotionButton,
        "txt-white",
        state === 1 ? styles["ReduceMotionButton--on"] : "",
      ])}
      onClick={handleClick}
    >
      {globalLocale.reduce_motion}
      <svg className={styles.ReduceMotionButton__icon} width="9" height="8">
        <path
          stroke="#FFF"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M117 9l-4.665 5.497L110 12"
          transform="translate(-109 -8)"
          fill="none"
          fillRule="evenodd"
        />
      </svg>
    </button>
  )
}

ReduceMotionButton.propTypes = {
  language: PropTypes.string,
}

export default ReduceMotionButton
