import React, { useEffect } from "react"
import PropTypes from "prop-types"

import useTracking from "../hooks/use-tracking"

import Navigation from "../components/navigation/navigation"

const Layout = ({ className, children, pageName }) => {
  const { trackPage } = useTracking()

  /**
   * Run the tracking. This must only be run on mount
   */
  useEffect(() => {
    trackPage(pageName)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={className}>
      <Navigation />
      {children}
    </div>
  )
}

Layout.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  pageName: PropTypes.string,
}

export default Layout
