import React, { useState, useRef, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"

import ReduceMotionButton from "../reduce-motion-button/reduce-motion-button"
import Image from "../image/image"
import Button from "../button/button"
import Icon from "../icon/icon"

import useEventListener from "../../hooks/use-event-listener"
import useLocale from "../../hooks/use-locale"
import useBreakpoints from "../../hooks/use-breakpoints"
import useTracking from "../../hooks/use-tracking"

import { classnames, fluidImageWithDefault } from "../../utils/helpers"

import styles from "./navigation.module.scss"

import switchLogoImg from "../../images/global/logo-switch.svg"
import useReduceMotion from "../../hooks/use-reduce-motion"

export default function Navigation() {
  const currentBP = useBreakpoints()
  const [isSticky, setIsSticky] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [stickyIn, setStickyIn] = useState(false)
  const mainRef = useRef()
  const { globalLocale, langKey } = useLocale()
  const stickyTimer = useRef()
  const { trackLink } = useTracking()
  const [rmState] = useReduceMotion()
  const images = useStaticQuery(graphql`
    query {
      marioHead: file(relativePath: { eq: "navigation/mario-head.png" }) {
        childImageSharp {
          fixed(width: 219) {
            ...fluidImageWithDefault
          }
        }
      }
    }
  `)

  const toggleNav = () => {
    setIsOpen(cur => !cur)
  }

  useEventListener("scroll", () => {
    if (window.scrollY > 200) setIsSticky(true)
    else setIsSticky(false)
  })

  useEventListener("resize", () => {
    if (currentBP > 1) setIsOpen(false)
  })

  useEffect(() => {
    if (isOpen) document.body.style.overflow = "hidden"
    else document.body.style.overflow = "visible"
  }, [isOpen])

  useEffect(() => {
    clearTimeout(stickyTimer.current)

    if (isSticky)
      setTimeout(() => {
        setStickyIn(true)
      }, 100)
    else setStickyIn(false)
  }, [isSticky])

  return (
    <nav
      className={classnames([
        styles.Navigation,
        isSticky ? styles["Navigation--sticky"] : "",
        stickyIn ? styles["Navigation--sticky-in"] : "",
        isOpen ? styles["Navigation--open"] : "",
        rmState === 1 && styles["is-reduced-motion"],
      ])}
      aria-label="Page controls"
    >
      <div
        className={classnames([styles.Navigation__motion, "pattern--hashes"])}
      >
        <ReduceMotionButton />
      </div>
      <div ref={mainRef} className={styles.Navigation__wrapper}>
        <Button
          className={classnames([
            styles.Navigation__toggler,
            "pattern--hashes",
            "txt-weight-bold",
          ])}
          aria-expanded={isOpen.toString()}
          aria-controls="main-nav__nav"
          aria-live="polite"
          onClick={toggleNav}
          flat={true}
          withFrame={true}
        >
          {isOpen ? globalLocale.close : globalLocale.navigation.menu}
        </Button>
        <nav
          className={classnames([styles.Navigation__nav, "pattern--hashes"])}
          id="main-nav__nav"
          aria-label={globalLocale.navigation.label}
        >
          <button
            className={styles["Navigation__nav-close"]}
            onClick={toggleNav}
            aria-controls="main-nav__nav"
          >
            <span className="visually-hidden">
              {globalLocale.navigation.menu}
            </span>
            <Icon id="fat-x" />
          </button>
          <ul className={styles.Navigation__list}>
            {globalLocale.navigation.items.map((item, index) => (
              <li
                key={`nav-list-item-${index}`}
                className={styles["Navigation__list-item"]}
              >
                <Button
                  className={styles.Navigation__anchor}
                  to={item.url}
                  flat={true}
                  withFrame={true}
                  onClick={event => {
                    trackLink(
                      {
                        eVars: {
                          60: item.label.toLowerCase(),
                        },
                        events: [77],
                      },
                      event
                    )
                  }}
                >
                  <span>{item.label}</span>
                </Button>
              </li>
            ))}
          </ul>
          <div className={styles["Navigation__mario-head"]}>
            <Image {...images.marioHead.childImageSharp.fixed} alt="" />
          </div>
        </nav>
      </div>
      <div className={styles.Navigation__switch}>
        <a
          href={
            langKey === "ca"
              ? "//www.nintendo.com/en_CA/switch/"
              : globalLocale.navigation.switch.url
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={switchLogoImg} alt={globalLocale.navigation.switch.alt} />
        </a>
      </div>
    </nav>
  )
}
